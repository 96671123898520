import React from "react";
import ReactDOM from "react-dom";
import { AppContainer } from "./components/app/AppContainer";
import "./assets/style.scss";
import "swiper/css";
import "swiper/css/pagination";

import "swiper/css/navigation";
import 'antd/dist/antd.css'; 
import "@n3/react-vision-panel/dist/vision-panel.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
import "./components/ui/i18next";

import { HelmetProvider } from 'react-helmet-async';
ReactDOM.render(
    <HelmetProvider>

<AppContainer />

    </HelmetProvider>,
  document.getElementById("root")
);
