import React, { useState } from "react";
import { PageTitle } from "../../ui/PageTitle";
import "./assets/history.scss";
import img1 from "./assets/1.png";
import img2 from "./assets/2.png";
import img3 from "./assets/3.jpg";
import img4 from "./assets/4.png";
import img5 from "./assets/5.jpg";
import img6 from "./assets/6.png";
import img8 from "./assets/8.png";
import img9 from "./assets/9.png";
import img10 from "./assets/10.png";
import img7 from "./assets/7.png";
import logo from './assets/logo.gif'
import 'antd/dist/antd.css';
import Abduqahhorov from './assets/Abduqahhorov.png'
import Batirov from './assets/Batirov.png'
import Mamedov from './assets/Mamedov.png'
import Niyazov from './assets/Niyazov.png'
import Shaxmardonov from './assets/Shaxmardonov.png'
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import { Swiper, SwiperSlide } from "swiper/react";
import {Row, Col} from "antd"
import SwiperCore, { Navigation, Autoplay } from "swiper";
import { useTranslation } from 'react-i18next';

SwiperCore.use([Navigation, Autoplay]);

function History() {
  const {t} = useTranslation();
  const [loader, setLoader] = useState(true)
  const loaderT=()=>{
      setTimeout(()=>{
          setLoader(false)
      }, 0)
  }
  return (
    <div className="history" onLoad={()=>{loaderT()}}>
    {loader?<div className="loaderG">
    <div className="befG">
<img src={logo} alt="..."/>
</div>

</div>
:''}
      <div className="container">
        {" "}
        <PageTitle title={t('TASHKILOTI TARIXI')} />
        <div className="containerr">
          <div className="history-slider">
            <Swiper
              slidesPerView={1}
              spaceBetween={30}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              loop={true}
              navigation={true}
              className="mySwiper"
            >
              <SwiperSlide>
                <div
                  className="swiper-slide_body"
                  style={{
                    background: `url(${img1})center center no-repeat `,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  <div className="swiper-slide_bg" />
                  <div className="swiper-slide_content">
                    <p className="swiper-slide_content_title">
                    {t('history1')}
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="swiper-slide_body"
                  style={{
                    background: `url(${img2}) center center no-repeat`,
                    backgroundSize: "cover",
                  }}
                >
                  <div className="swiper-slide_bg" />
                  <div className="swiper-slide_content">
                    <p className="swiper-slide_content_title">
                    {t('history2')}
                     
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="swiper-slide_body"
                  style={{
                    background: `url(${img3}) center center no-repeat`,
                    backgroundSize: "cover",
                  }}
                >
                  <div className="swiper-slide_bg" />
                  <div className="swiper-slide_content">
                    <p className="swiper-slide_content_title">
                    {t('history3')}
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="swiper-slide_body"
                  style={{
                    background: `url(${img4}) center center no-repeat`,
                    backgroundSize: "cover",
                  }}
                >
                  <div className="swiper-slide_bg" />
                  <div className="swiper-slide_content">
                    <p className="swiper-slide_content_title">
                    {t('history4')} 
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="swiper-slide_body"
                  style={{
                    background: `url(${img5}) center center no-repeat`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  <div className="swiper-slide_bg" />
                  <div className="swiper-slide_content">
                    <p className="swiper-slide_content_title">
                    {t('history5')} 
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="swiper-slide_body"
                  style={{
                    background: `url(${img6}) center center no-repeat`,
                    backgroundSize: "cover",
                  }}
                >
                  <div className="swiper-slide_bg" />
                  <div className="swiper-slide_content">
                    <p className="swiper-slide_content_title">
                    {t('history6')}  
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="swiper-slide_body"
                  style={{
                    background: `url(${img7}) center center no-repeat`,
                    backgroundSize: "cover",
                  }}
                >
                  <div className="swiper-slide_bg" />
                  <div className="swiper-slide_content">
                    <p className="swiper-slide_content_title">
                    {t('history7')} 
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="swiper-slide_body"
                  style={{
                    background: `url(${img8}) center center no-repeat`,
                    backgroundSize: "cover",
                    backgroundPosition: "top",
                  }}
                >
                  <div className="swiper-slide_bg" />
                  <div className="swiper-slide_content">
                    <p className="swiper-slide_content_title">
                    {t('history8')}
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="swiper-slide_body"
                  style={{
                    background: `url(${img9}) center center no-repeat`,
                    backgroundSize: "cover",
                    backgroundPosition: "top",
                  }}
                >
                  <div className="swiper-slide_bg" />
                  <div className="swiper-slide_content">
                    <p className="swiper-slide_content_title">
                    {t('history9')}
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="swiper-slide_body"
                  style={{
                    background: `url(${img10}) center center no-repeat`,
                    backgroundSize: "cover",
                    backgroundPosition: "top",
                  }}
                >
                  <div className="swiper-slide_bg" />
                  <div className="swiper-slide_content">
                    <p className="swiper-slide_content_title">
                    {t('history10')}
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="section-two">
            <p dangerouslySetInnerHTML={{__html:t('hisq')}}>
           
            </p>
           
            <div className="section-title">
              <strong>
                <p> {t('hist')} </p>
              </strong>
            
<Row style={{display:'flex', justifyContent:'center'}}>
<Col lg={8} md={12} sm={24}>
<div style={{padding:'10px'}}>
                  <div className="hisRah" style={{backgroundImage:`url(${Shaxmardonov})`}}>
                    <div className="hisBlack">
<p className="kasb">{t("hisy")}</p>
<h2 className="ism">{t("hisyI")}</h2>
<p className="yil">{t("hisu")}</p>
                    </div>
                    </div>
                </div>
    
</Col>
<Col lg={8} md={12} sm={24}>
<div style={{padding:'10px'}}>
                  <div className="hisRah" style={{backgroundImage:`url(${Abduqahhorov})`}}>
                    <div className="hisBlack">
<p className="kasb">{t("hisi")}</p>
<h2 className="ism">{t("hisiI")}</h2>
<p className="yil">{t("hiso")}</p>
                    </div>
                    </div>
                </div>
    
  </Col>
  <Col lg={8} md={12} sm={24}>
<div style={{padding:'10px'}}>
                  <div className="hisRah" style={{backgroundImage:`url(${Mamedov})`}}>
                    <div className="hisBlack">
<p className="kasb">{t("hisp")}</p>
<h2 className="ism">{t("hispI")}</h2>
<p className="yil">{t("hiss")}</p>
                    </div>
                    </div>
                </div>
    
</Col>
<Col style={{marginTop:'-20px'}} lg={8} md={12} sm={24}>
<div style={{padding:'10px'}}>
                  <div className="hisRah" style={{backgroundImage:`url(${Niyazov})`}}>
                    <div className="hisBlack">
<p className="kasb">{t("hish")}</p>
<h2 className="ism">{t("hishI")}</h2>
<p className="yil">{t("hisj")}</p>
                    </div>
                    </div>
                </div>
    
  </Col>
  <Col style={{marginTop:'-20px'}} lg={8} md={12} sm={24}>
<div style={{padding:'10px'}}>
                  <div className="hisRah" style={{backgroundImage:`url(${Batirov})`}}>
                    <div className="hisBlack">
<p className="kasb">{t("hisk")}</p>
<h2 className="ism">{t("hiskI")}</h2>
<p className="yil">{t("hisl")}</p>
                    </div>
                    </div>
                </div>
    
</Col>
</Row>
           
                
                
                
                
                
                
               
            
            
            
            <br/>
            <br/>
            <br/>
            
            
            
            
            
        
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default History;
