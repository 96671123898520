import axios, { AxiosInstance } from "axios";
//@ts-ignore
const http: AxiosInstance = axios.create({
    baseURL: "https://vatanparvar.uz/",
    auth: {
        username: 'vatanparvar',
        password: 'web@#1234'
    }
})

export default http
