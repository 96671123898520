import {
  Form,
  Input,
  
  InputNumber,
  Cascader,
  Select,
  message,
  Checkbox,
  Button,
  AutoComplete,
  Radio,
} from 'antd';
import http from "../ui/Services";
import { useForm } from "react-hook-form";
import logo from './assets/logo.gif'

import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import MaskedInput from "antd-mask-input";
import "./assets/murojat.scss";
import { QuestionOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import { useEffect, useState } from "react";
import { PageTitle } from "../ui/PageTitle";
import { useTranslation } from "react-i18next";
import { Col, Row ,  Tooltip } from 'antd';
import style from './Taklif.module.css'
import { FaTelegramPlane } from 'react-icons/fa';
const layout = {
  labelCol: { span: 2 },
  wrapperCol: { span: 22 },
};
const { Option } = Select;

/* eslint-disable no-template-curly-in-string */

/* eslint-enable no-template-curly-in-string */

function TaklifSend() {
  const { reset, register } = useForm();
 
  const [firstName, setfirstName]=useState(false)
  const [title, settitle]=useState(false)
  const [text, settext]=useState(false)
  const [textClass, settextClass]=useState(false)
  const [textData, settextData]=useState("")
  const [emailClass, setemailClass]=useState(false)
  const [phone, setphone]=useState(false)
  const [firstNameClass, setfirstNameClass]=useState(false)
  const [titleClass, settitleClass]=useState(false)
  const [lastNameClass, setlastNameClass]=useState(false)
  const [phoneClass, setphoneClass]=useState(false)
  const [loader, setLoader] = useState(true)
  const [form] = Form.useForm();
  const [regions, setRegions] = useState<any>(null)
  const [regionsClass, setRegionsClass] = useState<any>(false)
  const [typeClass, settypeClass] = useState<any>(false)
  const [typeS, settypeS] = useState<any>(false)
  const [secondName, setsecondName]=useState(false)
  const [secondNameClass, setsecondNameClass]=useState(false)
  const [type, setType]=useState(0)
 
  const [typeSoha, setTypeSoha]=useState<any>(1)
  const [lastName, setlastName]=useState(false)
    const { t } = useTranslation();
  const onFinish = (values: any) => {
    const formData = new FormData();
    if(textData.length<70){
     settext(true)
    }else{
      settext(false)
          
setLoader(true)


formData.append("fullName", values.firstName[0].toUpperCase()+values.firstName.slice(1,values.firstName.length)+' '+values.lastName[0].toUpperCase()+values.lastName.slice(1,values.lastName.length))
formData.append("phone", values.phone)

formData.append("murojaatMazmuni", values.title[0].toUpperCase()+values.title.slice(1,values.title.length))
formData.append("murojaatMatni", textData[0].toUpperCase()+textData.slice(1,textData.length))
formData.append("regionId", values.regionId)
formData.append("sohaId", typeSoha)
formData.append("isAnonim", String(type))

http.post<any>(`/PostApi/Taklif/`, formData, {
  headers: { "Content-Type": "multipart/form-data" }
}).then(() => {
     message.success({content:"Ma'lumotlaringiz qabul qilindi",
       className: 'custom-class',
    style: {
      marginTop: '30vh',
      padding:'30px',
      fontSize:'20px'
    },})
    
setLoader(false)
  
setfirstName(false)
settitle(false)
settext(false)
settextClass(false)
settextData("")
setemailClass(false)
setphone(false)
setfirstNameClass(false)
settitleClass(false)
setlastNameClass(false)
setphoneClass(false)
setLoader(false)
setlastName(false)
settypeS(false)
settypeClass(false)
setRegionsClass(false);
     form.resetFields();
   
  })
  .catch(e =>        {message.error({content:"Ma'lumotlaringiz qabul qilinmadi keyinroq yana urinib ko'ring", 
    className: 'custom-class',
    style: {
      marginTop: '30vh',
      padding:'30px',
      fontSize:'20px'
    },});  
    setLoader(false)}
  )
 
    }

  };
  useEffect(()=>{
getRegion()
  },[])
  const getRegion = () => {
    http.get<any>(`GetApi/GetRegionContacts`)
        .then((res) => {setRegions(res.data); setLoader(false)})
        .catch(e => console.log(e))
}
  const loaderT=()=>{
      setTimeout(()=>{
          setLoader(false)
      }, 500)
  }
  const checkIsm=(value:any)=>{
    var a=value.target.value
    var b="" 
    var f=true 
    for(let i=0; i<a.length; i++){
  
        if(a.charCodeAt(i)===39 || a.charCodeAt(i)===44 || (a.charCodeAt(i)>=65 && a.charCodeAt(i)<=90) || (a.charCodeAt(i)>=97 && a.charCodeAt(i)<=122)){
          b+=a[i]
        }else{
          f=false
        }
      }
      if(value.target.value.length>0){
        setfirstNameClass(true)
    
      }else{
        setfirstNameClass(false)
      }
        form.setFieldsValue({
          firstName:b
        })
   
     
    }
   
const checkFam=(value:any)=>{
  var a=value.target.value
  var b="" 
  var f=true 
  for(let i=0; i<a.length; i++){

      if(a.charCodeAt(i)===39 || a.charCodeAt(i)===44 || (a.charCodeAt(i)>=65 && a.charCodeAt(i)<=90) || (a.charCodeAt(i)>=97 && a.charCodeAt(i)<=122)){
        b+=a[i]
      }else{
        f=false
      }
    }
    if(value.target.value.length>0){
      setlastNameClass(true)
  
    }else{
      setlastNameClass(false)
    }
      form.setFieldsValue({
        lastName:b
      })
 
}
const onChange = (e: CheckboxChangeEvent) => {
 if(e.target.checked){
  setType(1)
 }else{
  setType(0)

 }
};
const checktext=(value:any)=>{
  if(value.target.value.length>0){
    settextClass(true)

  }else{
    settextClass(false)
  }
  settextData(value.target.value);
  if(value.target.value.length>70)
  {settext(false)
  }else
  settext(true)   
}
const checktitle=(value:any)=>{
  if(value.target.value.length>0){
    settitleClass(true)

  }else{
    settitleClass(false)
  }
  
 
}
  
  const checkphone=(value:any)=>{
    if(value.target.value.length>0){
      setphoneClass(true)

    }else{
      setphoneClass(false)
    }


  var v=value.target.value
 
    if(v.indexOf("_")===-1)
       setphone(false)
    else
    setphone(true)   
  }
  
  const checkEmail=(value: any)=>{
    if(value.target.value.length>0){
      setemailClass(true)
  
    }else{
      setemailClass(false)
    }
  }
  


  return ( <div className="murojat" onLoad={()=>{loaderT()}}>
  {loader?<div className="loaderG">
  <div className="befG">
<img src={logo} alt="..."/>
</div>

</div>
:''}
  <div className="container">
      <PageTitle title={t('Taklif yuborish')} />
      <br/>
      <br/>
      <br/>
      <br/>
      <div className="form">
      <Form  form={form} {...layout} onFinish={onFinish} validateMessages={{
  required: t("Maydonni to'ldirish shart"),
  types: {
    email: t("Emailni to'g'ri kiriting"),
    },
  
}}>
        <Row>
       
        <Col className={style.lt} lg={24} md={24} sm={24}>
         <Row className={style.lt}>
         <Col className={style.cl} style={{padding:'0px 20px 30px 20px'}} lg={8} sm={24}>
         <div style={{height:'120px', display:'flex', justifyContent:'center'}} onClick={()=>{setTypeSoha(1)}} className={typeSoha===1?style.active:style.card}>

<h4>{t("Vatanparvarlik harakati yo'nalishi")}</h4>

    </div>

        </Col>
       

        <Col className={style.cl} style={{padding:'0px 20px 30px 20px'}} lg={8} sm={24}>
       <div style={{height:'120px', display:'flex', justifyContent:'center'}} onClick={()=>{setTypeSoha(3)}} className={typeSoha===3?style.active:style.card}>

<h4>{t("Sport yo'nalishi")}</h4>

    </div>
      </Col>
      <Col className={style.cl} style={{padding:'0px 20px 30px 20px'}} lg={8} sm={24}>
       <div style={{height:'120px', display:'flex', justifyContent:'center'}} onClick={()=>{setTypeSoha(2)}} className={typeSoha===2?style.active:style.card}>

<h4>{t("Kasbga tayyorlash yo'nalishi")}</h4>

    </div>
        </Col>
          </Row> 
        </Col>
        <Col style={{padding:'0px 20px'}} lg={12} md={24} sm={24}>


            <Form.Item  data-placeholder={t("Ism")}  label=" "  className={`myFor ${firstName?"salom":''} ${firstNameClass?"forPlace":''}`}  tooltip={{ title: t("Lotin tilida yozing") , icon: <QuestionOutlined style={{color:'white'}} /> }}   style={{width:'100%'}} name='firstName'   rules={[{ required: true, message: t("check")?`${t("Ism")}${t("ni kirtishingiz shart")}`:`${t("ni kirtishingiz shart")} ${t("Ism")}` }]} >
          <Input onChange={(e)=>{checkIsm(e)}} className="in1 ot" placeholder={t("Ism")} />
        </Form.Item>
      <Form.Item data-placeholder={t("Familiya")}  label=" "  tooltip={{ title: t("Lotin tilida yozing") , icon: <QuestionOutlined style={{color:'white'}} /> }}   style={{width:'100%'}} name='lastName'  className={`myFor ${lastName?"salom":''} ${lastNameClass?"forPlace":''}`}  rules={[{ required: true, message: t("check")?`${t("Familiya")}${t("ni kirtishingiz shart")}`:`${t("ni kirtishingiz shart")} ${t("fam")}` }]} >
          <Input onChange={(e)=>{checkFam(e)}}  className="in1 ot" placeholder={t("Familiya")} />
         
      </Form.Item>
      
          </Col>
          <Col style={{padding:'0px 20px'}} lg={12} md={24} sm={24} >

          <Form.Item data-placeholder={t("Telefon raqam")} label=" "  className={`myFor ${phone?"salom":''} ${phoneClass?"forPlace3":''}`} tooltip={{ title: t("Telefon raqamni ko'rsatilgan formatda kiriting") , icon: <QuestionOutlined style={{color:'white'}} /> }}   style={{width:'100%'}} name='phone'  rules={[{ required: true, message: t("check")?`${t("Telefon raqam")}${t("ni kirtishingiz shart")}`:`${t("ni kirtishingiz shart")} ${t("Telefon raqam")}` }]} >
<MaskedInput className='in' mask="(11) 111 11 11" onChange={(e)=>{checkphone(e)}}  addonBefore="+998"   placeholder={t("Telefon raqam")}/>

</Form.Item>

      <Form.Item data-placeholder={t("Viloyat")}  className={`myFor ${regionsClass?"forPlace3":''}`}  label=" "  tooltip={{ title: t("Viloyatni e'tibor bilan tanlang") , icon: <QuestionOutlined style={{color:'white'}} /> }} name="regionId"   rules={[{ required: true, message: t("check")?`${t("Viloyat")}${t("ni tanlashingiz shart")}`:`${t("ni tanlashingiz shart")} ${t("Viloyat")}` }]}>
      <Select
        className="border"
        placeholder={!regionsClass?t("Viloyat"):''}
        onChange={(value) => {  setRegionsClass(true); }}
        disabled={typeS} 
      >
      
       {regions!==null?regions.map((item:any)=>{
           return(<Option value={item.regionId}>{t("check")?item.regionId!==1 && item.regionId!==14?item.regionName.slice(0, item.regionName.indexOf('kengashi')-1)+"i":item.regionName.slice(0, item.regionName.indexOf('kengashi')-1):item.regionId!==1?item.regionId!==14?item.regionNameRu.slice(0, item.regionNameRu.indexOf('кий'))+"кая область":"город Ташкент":"Республика Каракалпакстан"}</Option>)
       }):""} 
       
      </Select>
    
    </Form.Item>
</Col>
<Col lg={24} md={24} sm={24} style={{padding:'0px 20px', marginTop:'-20px'}}>
<Form.Item  data-placeholder={t("Taklif mazmuni")}  label=" "  className={`myFor1 myFor3 ${title?"salom":''} ${titleClass?"forPlace5":''}`}  tooltip={{ title: t("Taklif mazmunini yoriting") , icon: <QuestionOutlined style={{color:'white'}} /> }}   style={{width:'100%'}} name='title'   rules={[{ required: true, message: t("check")?`${t("Title")}${t("ni kirtishingiz shart")}`:`${t("ni kirtishingiz shart")} ${t("Title")}` }]} >
          <Input   onChange={(e)=>{checktitle(e)}} className="in1" placeholder={t("Taklif mazmuni")} />
        </Form.Item>
</Col>
<Col lg={24} md={24} sm={24} style={{padding:'0px 20px', marginTop:'-40px'}}  className={text?"salom":''}>
<Form.Item  data-placeholder={t("Taklif matni")} className={`myFor1 in ${textClass?"forPlace1":''}`} tooltip={{ title: "Lotin tilida yozing", icon:  <QuestionOutlined style={{color:'white'}} /> }}   >
<div className="dateTool">
     <Tooltip placement="top" title={t("Taklifni ko'proq yoriting")}>
<QuestionOutlined />  </Tooltip>
     </div>
        <Input.TextArea 
        onChange={(e)=>{checktext(e)}}
       style={{width:"100%", position:'relative'}}
          id="fr" 
          rows={20}
        value={textData}
           placeholder={t("Taklif matni")} />
        {text?<p className="error1">{t("Taklifni ko'proq yoriting")}</p>:''}
      </Form.Item>
      <Checkbox className="checkBox" onChange={onChange}><p style={{fontSize:'16px'}}>{t("Anonim tarzda yuborish")}</p></Checkbox>
      </Col>
      <Col lg={24} md={24} sm={24}  style={{padding:'0px'}} className="butCol">
      <Form.Item   style={{width:'100%',  display:'flex', justifyContent:'center'}}>
        <button type="submit" style={{height:'55px'}} className={style.btn}>
        {t("Yuborish")}
        <FaTelegramPlane style={{ fontSize:'23px', position:'relative', top:'4px', left:'5px'}}/>
        </button>
      </Form.Item>
      
        </Col>
        </Row>
      </Form>
    </div>
            </div>
        </div>
  );
};

export default TaklifSend;