import React, { useEffect, useState } from "react";
import "./assets/vacancy.css";
import { useTranslation } from 'react-i18next';

import http from "../ui/Services";
import { PageTitle } from "../ui/PageTitle";
import { Container, Row } from "react-bootstrap";
export default function Vacancy() {
    const {t} = useTranslation();
  const [loader, setLoader] = useState(true)
  const [data, setData] = useState<any>(null)
 
  useEffect(()=>{
    http.get<any>(`/GetApi/GetVacancy/`)
        
    .then((res) => {console.log(res.data); setData(res.data);
       setTimeout(()=>{
      setLoader(false)
  }, 0) })
    .catch(e => console.log(e))
  },[])
  return (
    <div>
      <br/>
        <PageTitle title={t('vacancy')} />
        <Container>
        
        <div className="vacancy">
        <div className="vacancy_title"><h1>{t("Xodimlar bilan ishlash bo'limi telefon raqami")} :</h1>
          <a href="tel: +998 (71) 234-85-21">+998 (71) 234-85-21</a>
          </div>
          {data!==null?data.map((item:any, key:any)=>{
                   return(<div className="vacancy_item">
                    <h1>{t('check')?item.nameUz:item.nameRu}</h1>
                    <p>{item.count}</p>
                   </div>)
          }):<></>}
          {data!==null && data.length===0?<h1 className="error_t">{t("Bizda bo'sh ish o'rni mavjud emas")}</h1>:<></>}
          
        </div>
        </Container>
    </div>
  )
}
