import React, {useState} from 'react';
import {HomeHeaderSlider} from "./HomeHeaderSlider";
import {Activity} from "./Activity";
import {News} from "./News";
import {Map} from "./Map";
import {Statistic} from "./Statistic";
import {Partners} from "./Partners";
import Location from './Location'

import YouTube from '@u-wave/react-youtube';
import { Helmet } from 'react-helmet-async';
import { Video } from './Video';


export function Home() {


    return (
        <div>
         
            <HomeHeaderSlider/>
            <Video />
            <Activity />
            <Statistic />
            <News />
          {/* <div style={{position:'relative'}}>
            <YouTube
                     
                     showCaptions={false}
                    showRelatedVideos={false}
                    //  controls={false}
                     autoplay={true}
                     muted={true}
                      video="-S0nJlR5zZQ"
                  style={{width:'80%', marginLeft:'10%', height:"400px"}}
                   
                    />
                    <div style={{backgroundColor:"transparent", width:'80%', height:'500px', position:'absolute', left:'10%', top:'90px'}}></div>
                    <br/>
                    <br/>
                    <br/>
                    </div> */}
            <Map />
            <Partners />
            <Location/>
        </div>
    );
}

