import React, { useEffect, useState } from 'react';
import {PageTitle} from '../ui/PageTitle' ;
import {HiOutlineLocationMarker} from "react-icons/hi";
import {AiFillYoutube, AiOutlineMail} from "react-icons/ai";
// import {AiOutlineInstagram} from "react-icons/ai";
import {FiPhone} from "react-icons/fi";
import {HiOutlineDocumentDownload} from "react-icons/hi";
import {RiFacebookFill} from "react-icons/ri"
import {BsInstagram} from "react-icons/bs"
import { api } from "../../host";
import person from './assets/placeHolder.png'
import {FaTelegramPlane} from "react-icons/fa"
import { YMaps, Map, Placemark, FullscreenControl, GeolocationControl, RouteButton, TrafficControl, ZoomControl, Clusterer} from 'react-yandex-maps';
import { useTranslation } from 'react-i18next';
import "./assets/location.scss";
import http from '../ui/Services';

function Location() {
    const {t} = useTranslation();
const [regions,setRegions] = useState<any>({})
    useEffect(()=>{
        http.get<any>(`GetApi/GetRegionContacts`)
        .then((res) => {setRegions(res.data);})
        .catch(e => console.log(e))
    },[])
    return (
        <div className='location'>
            <PageTitle title={t('MAP')}/>
            <div className="container">
              <div className="location-container">
                <div className="location-info">
                    <div className="location-info-address">
                        <a href="https://goo.gl/maps/ZS6VGNh84NJsykTr7"  className='iconn'><HiOutlineLocationMarker className="icon" size="2.1rem" color="#133165" cursor="pointer"/></a>
                       <a href="https://goo.gl/maps/ZS6VGNh84NJsykTr7">{t("mk-address")}<br/>{t("mk-address1")} </a>  
                    </div>
                    <div className="location-info-email">
                        <p key="icon" className='iconn'><AiOutlineMail className="icon" size="2.1rem" color="#133165" cursor="pointer"/></p>
                        <a href={`mailto: info@vatanparvar.uz`}  key="email" className="email">info@vatanparvar.uz</a>  
               
                    </div>     
                    <div className="location-info-phone">
                        <div className="location-info-phone-icon">
                            <FiPhone className="icon" size="2.1rem" color="#133165" style={{marginTop:'10px'}} cursor="pointer"/>
                        </div>
                        <div className="location-info-phone-nuber">
                           <p className=""><a href="tel:+998712348521">+998 (71) 234 - 85 - 21</a></p>
                          
                        </div>
                    </div>    
                    <div className="location-info-document">
                        <HiOutlineDocumentDownload className="icon" size="2.1rem" color="#133165" cursor="pointer"/>
                        <a href={`${api}/Files/MK.docx`} target="_blank">{t("Rekvizitlar")}</a>  
                    </div>     
                    <div className="location-info-social" style={{marginTop:'30px'}}>
                       <a target="_blank" href="https://www.facebook.com/Mudofaaga-komaklashuvchi-Vatanparvar-tashkiloti-108212348006242" className="location-info-social-item_item" >
                          <RiFacebookFill size="0.9em" />
                       </a>
                       <a target="_blank" href="https://www.instagram.com/vatanparvar.tashkiloti/" className="location-info-social-item_item" >
                            <BsInstagram size="0.9em" />
                        </a>
                        <a target="_blank" href="https://t.me/mudofaavatanparvartashkiloti" className="location-info-social-item_item" >
                            <FaTelegramPlane size="0.9em" />
                        </a>
                        <a target="_blank" href="https://www.youtube.com/channel/UCI41Xe2P3GPUz5YK-TzEJZg" className="location-info-social-item_item" >
                            <AiFillYoutube size="0.9em" />
                        </a>
                    </div>      
                </div>
                <div className="location-map">
       
                   <div   style={{borderRadius:"10px",boxShadow: " rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px", overflow:"hidden", width:"100%", height:"368px"}}>
                   <YMaps  className="map-sect">
                       <div  >
                           <Map  width="100%" height="368px" defaultState={{
                               center: [41.328925, 69.280003] ,
                               zoom:17,
                               }}
                               >
                              <Clusterer
                  options={{
                    groupByCoordinates: false,
                  }}
                >
                  <Placemark
                    key={-1}
                    geometry={[41.328925, 69.280003]}
                    options={{
                      iconLayout: "default#image",
                      iconImageHref: person,
                      iconImageSize: [50, 80],
                      iconImageOffset: [-25, -90],
                    }}
                   
                    modules={["geoObject.addon.hint"]}
                  />
                </Clusterer>
                              <FullscreenControl options={{float: 'left'}}/>   
                              <GeolocationControl options={{float: 'left'}}/>
                              <RouteButton options={{float: 'right'}}/>
                              <TrafficControl options={{float: 'right'}}/>
                              <ZoomControl options={{float: 'left'}}
                              />
                            </Map>
                       </div>
                   </YMaps>
                   </div>
                </div> 
            </div>  
            
            </div>

        </div>
    )
}


export default Location;
