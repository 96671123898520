
import { useEffect, useState } from 'react';
import { Image } from 'antd';
import { Swiper, SwiperSlide } from 'swiper/react';
import "./assets/OpenNews.scss";
import { Link, Navigate, useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { api, url } from '../host';
import { useStore } from '../store/Store';
import http from '../components/ui/Services';




function OpenJob() {
    const { t } = useTranslation();
    const params = useParams();
    const { id } = params;
    const [error, seterror]=useState(false)
    const jobs=useStore(state=>state.jobs)
    const setjobs=useStore(state=>state.setjobs)
    const [idT, setidT] = useState<any>(Number(id))
    useEffect(()=>{
      if(jobs===null){
          http.get<any>(`/GetApi/GetJobsApi/`)
        .then((res) => {
          setjobs(res.data);
        if(!res.data[idT]){
          seterror(true)
        }
        })
          .catch(e => console.log(e))
      }
  
  },[])

     return (

        <div className="open-sports" style={{padding:'50px 18%'}}>{
            error?<Navigate to="/kasblar" />:
            jobs!==null?<div className="container" >
            <Row >
<Col lg={17} md={24} sm={24}>

<div className="open-sports-title">
                {Object.keys(jobs[idT]).length !== 0 &&
                  <h5>{t('lang') === 'uz' ? jobs[idT].titleUz : jobs[idT].titleRu}</h5>
  
                }
            </div>
          
            <Image.PreviewGroup>
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          loop={true}
          navigation={true}
          className="mySwiper"
        >
          <SwiperSlide>
          <div
                    className="swiper-slide_body"
                   style={{
                 
                      display:'flex',
                      alignItems:'center',
                      justifyContent:'center',
                
                      
                   }}
                  >
                    <Image className='red' style={{height:'auto', width:"100%" }} src={`${api}/Files/Jobs/${jobs[idT].titleImage}`}/>  
                  </div>
                  </SwiperSlide>
              
              {jobs[idT].jobsImage && jobs[idT].jobsImage.length!==0?jobs[idT].jobsImage.map((item: any, index: any)=>{
                  return(<SwiperSlide><div
                    className="swiper-slide_body"
                    style={{
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'center',
                        }}
                  >
                         <Image className='red' style={{height:'auto', width:"100%"}} src={`${api}/Files/Jobs/${item.imageUrl}`}/>  
                  </div>
                   </SwiperSlide>)

              }):''}
            
         
         
          
          
        </Swiper>

            <div className="open-sports-text">
               
                    <p className='salomText' style={{textAlign:'justify', lineHeight:"2", fontSize:'18px', textIndent:'50px'}} dangerouslySetInnerHTML={{__html: t('lang') === 'uz' ? jobs[idT].mainTextUz : jobs[idT].mainTextRu}}/>
               
            </div>
        </Image.PreviewGroup>
</Col>
<Col lg={7} md={0} sm={0} className="opensportitemCol">
<ul className="opensportitem">
   <h4>{t("jobs")}</h4>
   
   {jobs.map((item:any, key:any)=>{
       return( <li onClick={()=>{setidT(key)}}>
       <div  className="news_list-item1" key={key}>
        <div className='titles'>             
        <p className='tabTitle' style={{textTransform:'uppercase'}}>{t('lang') === 'uz' ? item.titleUz : item.titleRu}</p>
        </div></div>
        </li>)
   })}

</ul>
    </Col>

            </Row>
  
<br/>
<br/>

        </div>:<></>
         }
          
        </div>
    )
}

export default OpenJob;