import "./assets/activity.scss"
import {PageTitle} from "../ui/PageTitle";
import { useTranslation } from 'react-i18next';


export function Video() {

    const {t} = useTranslation();
   
    return (
        <div className="map video_div">
            <div className="container">
                <PageTitle title={t('markaziy_kengash')} />
                <div className="video_box">
                <div className="video_item">
      <video poster='https://vatanparvar.uz/Files/poster.jpg' controls src="https://vatanparvar.uz/Files/video.mp4" />
    <div className="audio">
    <p><b>“Vatanparvardir”</b> she’ri. Muallif: Rislig‘oy Hotamova. O‘qidi: Navbahor Darxonova.</p>
    <audio controls src="https://vatanparvar.uz/Files/sher.ogg" />
    </div>
    

   
                </div>
                </div>
            </div>
        </div>
    );
}

