import React, { useState } from 'react';
import { PageTitle } from '../../ui/PageTitle';
import "./assets/callcenter.scss";
import { useTranslation } from 'react-i18next';
import logo from './assets/logo.gif'
import call from './assets/call.png'
import telegram from './assets/telegram.png'
import { Col, Row } from 'antd';

function CallCenter() {
  const {t} = useTranslation();
  const [loader, setLoader] = useState(true)
  const loaderT=()=>{
      setTimeout(()=>{
          setLoader(false)
      }, 0)
  }
  return (
      <div className="callcenter" onLoad={()=>{loaderT()}}>
      {loader?<div className="loaderG">
      <div className="befG">
  <img src={logo} alt="..."/>
  </div>
  
  </div>
  :''}
      
          <PageTitle title={t('Call Center')}  />
          <div className="container">
            <div className="containerr">
            <div className="callcenter-img"></div>
            <div className="callcenter-text">
              <p>{t("call1")}</p>
              <p>{t("call2")}</p>
              <Row>
              <Col lg={8} md={12} sm={24}>
                  <a href={"tel: +998 (71) 234-85-62"} className='call_center_item'>
                    <img src={call}/>
                    <span>+998 (71) 234-85-62</span>
                  </a>
                </Col>
                <Col lg={8} md={12} sm={24}>
                  <a href={"tel: +998 (78) 150-31-51"} className='call_center_item'>
                    <img src={call}/>
                    <span>+998 (78) 150-31-51</span>
                  </a>
                </Col>
                <Col lg={8} md={12} sm={24}>
                  <a target="_blank" href={"https://t.me/+998909337733"} className='call_center_item'>
                    <img src={telegram}/>
                    <span>+998 (90) 933-77-33</span>
                  </a>
                </Col>
              </Row>
             
            </div>
            </div>
          </div>
      </div>
  );
}

export default CallCenter;
