import React, { useState , useEffect } from "react";
//import ReactExport from "react-data-export";
import { PageTitle } from "../ui/PageTitle";
import SwiperCore, { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "./assets/contacts.scss";
import style from  "./assets/style.module.css";

import { useTranslation } from 'react-i18next';
import http from "../ui/Services";
import logo from '../home/assets/logo.gif'
import {Row, Col, Collapse, Tooltip} from 'antd'
import { BsBank, BsBuilding,  BsEnvelope,  BsFillEnvelopeFill,  BsFillTelephoneFill,  BsPerson } from "react-icons/bs";
import {  AiOutlineFieldNumber, AiOutlinePhone } from "react-icons/ai";
import {  GiBanknote, GiMailbox, GiReceiveMoney } from "react-icons/gi";
import { MdFormatListNumbered, MdOutlinePlace } from "react-icons/md";
import { HiOutlineMailOpen } from "react-icons/hi";
import { RiBankCard2Line, RiBankCardFill, RiBankFill } from "react-icons/ri";
import { FaBuilding, FaMapMarkerAlt, FaRegEnvelope } from "react-icons/fa";
import { ImUserTie } from "react-icons/im";

const { Panel } = Collapse;


SwiperCore.use([Navigation, Autoplay]);


function Contacts() {
  const [index, setIndex]= useState(1)
  const {t} = useTranslation();
  const [number, setNumber]=useState(0)
  const[data , setData] = useState<any[]>([])
  const[region , setRegion] = useState<any[]>([])
  const [loader, setLoader] = useState(true)

  async function getContacts(){
   await http.get('GetApi/GetRegionContacts')
   .then((res) => {
      var b=[]
      b.push(res.data.filter((data: any) => data.regionId === 1))
      
      b.push(res.data.filter((data:any) => data.regionId === 2))
     
      b.push(res.data.filter((data:any) => data.regionId === 3))
     
      b.push(res.data.filter((data:any) => data.regionId === 4))
     
      b.push(res.data.filter((data:any) => data.regionId === 5))
     
      b.push(res.data.filter((data:any) => data.regionId === 6))
     
      b.push(res.data.filter((data:any) => data.regionId === 7))
      
      b.push(res.data.filter((data:any) => data.regionId === 8))
     
      b.push(res.data.filter((data:any) => data.regionId === 9))
     
      b.push(res.data.filter((data:any) => data.regionId === 10))
      
      b.push(res.data.filter((data:any) => data.regionId === 11))
     
      b.push(res.data.filter((data:any) => data.regionId === 12))
     
      b.push(res.data.filter((data:any) => data.regionId === 13))
     
      b.push(res.data.filter((data:any) => data.regionId === 14))
      setRegion(b)

   })
   .catch(e => console.log(e))

    const res = await http('/GetApi/GetBranchContacts/')
  
      var a=[]
     
     
      a.push(res.data.filter((data: any) => data.regionId === 1735))
      
      a.push(res.data.filter((data:any) => data.regionId === 1703))
     
      a.push(res.data.filter((data:any) => data.regionId === 1706))
     
      a.push(res.data.filter((data:any) => data.regionId === 1708))
     
      a.push(res.data.filter((data:any) => data.regionId === 1710))
     
      a.push(res.data.filter((data:any) => data.regionId === 1712))
     
      a.push(res.data.filter((data:any) => data.regionId === 1714))
      
      a.push(res.data.filter((data:any) => data.regionId === 1718))
     
      a.push(res.data.filter((data:any) => data.regionId === 1722))
     
      a.push(res.data.filter((data:any) => data.regionId === 1724))
      
      a.push(res.data.filter((data:any) => data.regionId === 1727))
     
      a.push(res.data.filter((data:any) => data.regionId === 1730))
     
      a.push(res.data.filter((data:any) => data.regionId === 1733))
     
      a.push(res.data.filter((data:any) => data.regionId === 1726))

      setData(a)
    
      setLoader(false)
      
  }
  
useEffect(()=>{
    getContacts();
  },[])
  const callback=(key:any)=> {
    setNumber(key)
 
  }
  
  
  return (
    <div className="contacts tr">
      
      {loader?<div className={style.loader}>
      <div className={style.bef}>
      <img src={logo} alt="..."/>
      </div>
      </div>
      :''}
      <div className="containerr">
      <PageTitle title={t("KONTAKTLAR")}/>
        <div className="tel">
        
        <div className="slider1"  style={{ zIndex:'333',  backgroundColor:'white'}}>
           
          <Swiper 
          slidesPerView={1} spaceBetween={0} breakpoints={{
              "670": {
                slidesPerView: 2,
              },
              "960": {
                slidesPerView: 3,
              },
              "1200": {
                slidesPerView: 5,
              },
            }}
            loop={true}
            navigation={true}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="swiper-slide_body">
                  <div  className={`slider-item_img ${index===1?`active`:null}`}  onClick={()=> setIndex(1)} >
                     <p>
                     {t("Qoraqalpog'iston")}
                    </p> 
                 </div>     
              </div>
            </SwiperSlide>   
            <SwiperSlide>
              <div className="swiper-slide_body">
                  <div  className={`slider-item_img ${index===2?`active`:null}`}  onClick={()=>setIndex(2)}  >
                     <p>
                     {t("Andijon")}
                    </p> 
                 </div>     
              </div>
            </SwiperSlide>
           
            <SwiperSlide>
              <div className="swiper-slide_body">
                  <div  className={`slider-item_img ${index===3?`active`:null}`} onClick={()=>setIndex(3)} >
                     <p>
                     {t("Buxoro")}
                    </p> 
                 </div>     
              </div>
            </SwiperSlide> 
            <SwiperSlide>
              <div className="swiper-slide_body">
                  <div  className={`slider-item_img ${index===12?`active`:null}`} onClick={()=>setIndex(12)} >
                     <p>
                     {t("Farg'ona")}
                    </p> 
                 </div>     
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide_body">
                  <div  className={`slider-item_img ${index===4?`active`:null}`} onClick={()=>setIndex(4)} >
                     <p>
                     {t("Jizzax")}
                    </p> 
                 </div>     
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide_body">
                  <div  className={`slider-item_img ${index===7?`active`:null}`} onClick={()=>setIndex(7)} >
                     <p>
                     {t("Namangan")}
                    </p> 
                 </div>     
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide_body">
                  <div  className={`slider-item_img ${index===6?`active`:null}`} onClick={()=>setIndex(6)} >
                     <p>
                     {t("Navoi")}
                    </p> 
                 </div>     
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide_body">
                  <div  className={`slider-item_img ${index===5?`active`:null}`} onClick={()=>setIndex(5)} >
                     <p>
                     {t("Qashqadaryo")}
                    </p> 
                 </div>     
              </div>
            </SwiperSlide>
           
            <SwiperSlide>
              <div className="swiper-slide_body">
                  <div  className={`slider-item_img ${index===8?`active`:null}`} onClick={()=>setIndex(8)} >
                     <p>
                     {t("Samarqand")}
                    </p> 
                 </div>     
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide_body">
                  <div  className={`slider-item_img ${index===10?`active`:null}`} onClick={()=>setIndex(10)} >
                     <p>
                     {t(" Sirdaryo")}
    </p> 
                 </div>     
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide_body">
                  <div  className={`slider-item_img ${index===9?`active`:null}`} onClick={()=>setIndex(9)} >
                     <p>
                     {t("Surxondaryo")}
                     </p> 
                 </div>     
              </div>
            </SwiperSlide>
           
            <SwiperSlide>
              <div className="swiper-slide_body">
                  <div  className={`slider-item_img ${index===11?`active`:null}`} onClick={()=>setIndex(11)} >
                     <p>
                     {t(" Toshkent vil")}
    </p> 
                 </div>     
              </div>
            </SwiperSlide>
           
           
            <SwiperSlide>
              <div className="swiper-slide_body">
                  <div  className={`slider-item_img ${index===13?`active`:null}`} onClick={()=>setIndex(13)} >
                     <p>
                     {t("Xorazm")}
                        </p> 
                 </div>     
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide_body">
                  <div  className={`slider-item_img ${index===14?`active`:null}`} onClick={()=>setIndex(14)} >
                     <p>
                     {t("Toshkent sh")}
                    </p> 
                 </div>     
              </div>
            </SwiperSlide>
           
          </Swiper>
        </div>
        <div className="sal">
                      <Collapse onChange={callback} accordion>
                      <Panel className={`${0===Number(number)?"Active":''} panel`}  key={0} header={
                              <div className={style.headAcc}>
                              <div className={style.headId}>{1}</div>
                              <div className={style.headName}><BsBuilding size='1.3rem' color="#133165"/>  {region.length!==0?t('check')?region[index - 1][0].regionName:region[index - 1][0].regionNameRu:''}</div>
                             </div>}>
                             <Row>
<Col style={{padding:'10px'}} className="colAcc" md={12} sm={24}>
  <BsPerson size='1.3rem'  color="#133165" /><span className="salomText">{region.length!==0?t('check')?(region[index - 1][0].regionPresident && region[index - 1][0].regionPresident!==null?region[index - 1][0].regionPresident:region[index - 1][0].regionPresidentRu && region[index - 1][0].regionPresidentRu!==null?region[index - 1][0].regionPresidentRu:<span style={{color:'#a2a0a0'}}>{t("Ma'lumot mavjud emas")}</span>):region[index - 1][0].regionPresidentRu && region[index - 1][0].regionPresidentRu!==null?region[index - 1][0].regionPresidentRu:region[index - 1][0].regionPresident && region[index - 1][0].regionPresident!==null?region[index - 1][0].regionPresident:<span style={{color:'#a2a0a0'}}>{t("Ma'lumot mavjud emas")}</span>:''}</span>
  </Col>
  <Col style={{padding:'10px'}} className="colAcc" md={12} sm={24}>
  <AiOutlinePhone size='1.3rem'  color="#133165" /><span className="salomText">{region.length!==0?region[index - 1][0].phoneNumber===null?<span style={{color:'#a2a0a0'}}>{t("Ma'lumot mavjud emas")}</span>:region[index - 1][0].phoneNumber:''}</span>
  </Col>
  <Col style={{padding:'10px'}} className="colAcc" md={12} sm={24}>
  <HiOutlineMailOpen size='1.3rem'  color="#133165" /><span className="salomText">{region.length!==0?region[index - 1][0].email==="null"?<span style={{color:'#a2a0a0'}}>{t("Ma'lumot mavjud emas")}</span>:region[index - 1][0].email:''}</span>
  </Col>
  <Col className="colAcc" md={12} sm={24}>
<Tooltip placement="left" title={t("Bank nomi")}><RiBankFill  color="#133165" size='1.4rem' /> <span className="salomText">{region.length!==0?t('check')?(region[index - 1][0].bankName && region[index - 1][0].bankName!==null?region[index - 1][0].bankName:region[index - 1][0].bankNameRu && region[index - 1][0].bankNameRu!==null?region[index - 1][0].bankNameRu:<span style={{color:'#a2a0a0'}}>{t("Ma'lumot mavjud emas")}</span>):region[index - 1][0].bankNameRu && region[index - 1][0].bankNameRu!==null?region[index - 1][0].bankNameRu:region[index - 1][0].bankName && region[index - 1][0].bankName!==null?region[index - 1][0].bankName:<span style={{color:'#a2a0a0'}}>{t("Ma'lumot mavjud emas")}</span>:''}</span></Tooltip>
  </Col>
 
  
  <Col className="colAcc" md={12} sm={24}>
  <Tooltip placement="left" title={t("Tashkilot manzili")}><FaMapMarkerAlt  color="#133165" size='1.4rem' />  <span className="salomText">{region.length!==0?t('check')?(region[index - 1][0].regionAdress && region[index - 1][0].regionAdress!==null?region[index - 1][0].regionAdress:region[index - 1][0].regionAdressRu && region[index - 1][0].regionAdressRu!==null?region[index - 1][0].regionAdressRu:<span style={{color:'#a2a0a0'}}>{t("Ma'lumot mavjud emas")}</span>):region[index - 1][0].regionAdressRu && region[index - 1][0].regionAdressRu!==null?region[index - 1][0].regionAdressRu:region[index - 1][0].regionAdress && region[index - 1][0].regionAdress!==null?region[index - 1][0].regionAdress:<span style={{color:'#a2a0a0'}}>{t("Ma'lumot mavjud emas")}</span>:''}</span></Tooltip>
  </Col>
  <Col className="colAcc" md={12} sm={24}>
  <Tooltip placement="left" title={t("Tashkilot hisob raqami")}><RiBankCardFill  color="#133165" size='1.4rem' /> <span className="salomText">{region.length!==0?region.length!==0?region[index - 1][0].bankAccount===null?<span style={{color:'#a2a0a0'}}>{t("Ma'lumot mavjud emas")}</span>:region[index - 1][0].bankAccount.slice(0, 4)+" "+region[index - 1][0].bankAccount.slice(4, 8)+" "+region[index - 1][0].bankAccount.slice(8, 12)+" "+region[index - 1][0].bankAccount.slice(12, 16)+" "+region[index - 1][0].bankAccount.slice(16, 20):'':''}</span></Tooltip>
  </Col>
  <Col className="colAcc" md={12} sm={24}>
  <Tooltip placement="left" title={t("INN")}><b style={{color:'#133165'}}>INN</b> <span style={{top:'0px'}} className="salomText">{region.length!==0?region.length!==0?region[index - 1][0].inn===null?<span style={{color:'#a2a0a0'}}>{t("Ma'lumot mavjud emas")}</span>:region[index - 1][0].inn.slice(0, 3)+" "+region[index - 1][0].inn.slice(3, 6)+" "+region[index - 1][0].inn.slice(6, 9):'':''}</span></Tooltip>
  </Col>
 
  <Col className="colAcc" md={12} sm={24}>
  <Tooltip placement="left" title={t("MFO")}> <b style={{color:'#133165'}}>MFO</b> <span style={{top:'0px'}} className="salomText">{region.length!==0?region.length!==0?region[index - 1][0].bankMfo===null?<span style={{color:'#a2a0a0'}}>{t("Ma'lumot mavjud emas")}</span>:region[index - 1][0].bankMfo:'':''}</span></Tooltip>
  </Col>
  <Col className="colAcc" md={12} sm={24}>
  <Tooltip placement="left" title={t("Pochta indeks")}><BsFillEnvelopeFill color="#133165" size='1.4rem'/> <span className="salomText">{region.length!==0?region.length!==0?region[index - 1][0].mailIndex===null?<span style={{color:'#a2a0a0'}}>{t("Ma'lumot mavjud emas")}</span>:region[index - 1][0].mailIndex:'':''}</span></Tooltip>
  </Col>
  
</Row>
  </Panel>
 
                          {
                              data.length !== 0 ? data[index - 1].map((item: any, key: any) =>
                                  
                              <Panel className={`${key+1===Number(number)?"Active":''} panel`}  key={key+1} header={
                              <div className={style.headAcc}>
                              <div className="headId">{key+2}</div>
                              <div className="headName"><BsBuilding size='1.1rem' color="#133165"/> {t('check')?item.branchName:item.branchNameRu}</div>
                            </div>}>
                       
<Row>
<Col style={{padding:'10px'}} className="colAcc" md={12} sm={24}>
  <BsPerson size='1.3rem'  color="#133165" /><span className="salomText"> {t('check')?item.branchPresident===null?<span style={{color:'#a2a0a0'}}>{t("Ma'lumot mavjud emas")}</span>:item.branchPresident:item.branchPresidentRu===null?<span style={{color:'#a2a0a0'}}>{t("Ma'lumot mavjud emas")}</span>:item.branchPresidentRu}</span>
  </Col>
  <Col style={{padding:'10px'}} className="colAcc" md={12} sm={24}>
  <AiOutlinePhone size='1.3rem'  color="#133165" /><span className="salomText"> {item.phoneNumber===null?<span style={{color:'#a2a0a0'}}>{t("Ma'lumot mavjud emas")}</span>:item.phoneNumber}</span>
  </Col>
  <Col style={{padding:'10px'}} className="colAcc" md={12} sm={24}>
  <HiOutlineMailOpen size='1.3rem'  color="#133165" /><span className="salomText"> {item.email==="null"?<span style={{color:'#a2a0a0'}}>{t("Ma'lumot mavjud emas")}</span>:item.email}</span>
  </Col>

 
  <Col style={{padding:'10px'}} className="colAcc" md={12} sm={24}>
  <RiBankFill  color="#133165" size='1.3rem' /> <span className="salomText">{t('check')?item.bankName===null?<span style={{color:'#a2a0a0'}}>{t("Ma'lumot mavjud emas")}</span>:item.bankName:item.bankNameRu===null?<span style={{color:'#a2a0a0'}}>{t("Ma'lumot mavjud emas")}</span>:item.bankNameRu}</span>
  </Col>
 
  
  <Col style={{padding:'10px'}} className="colAcc" md={12} sm={24}>
  <FaMapMarkerAlt  color="#133165" size='1.3rem' />  <span className="salomText">{t('check')?item.branchAdress===null?<span style={{color:'#a2a0a0'}}>{t("Ma'lumot mavjud emas")}</span>:item.branchAdress:item.branchAdressRu===null?<span style={{color:'#a2a0a0'}}>{t("Ma'lumot mavjud emas")}</span>:item.branchAdressRu}</span>
  </Col>
  <Col style={{padding:'10px'}} className="colAcc" md={12} sm={24}>
  <RiBankCardFill  color="#133165" size='1.3rem' /> <span className="salomText">{item.bankAccount===null?<span style={{color:'#a2a0a0'}}>{t("Ma'lumot mavjud emas")}</span>:item.bankAccount.slice(0, 4)+" "+item.bankAccount.slice(4, 8)+" "+item.bankAccount.slice(8, 12)+" "+item.bankAccount.slice(12, 16)+" "+item.bankAccount.slice(16, 20)} </span>
  </Col>
  <Col style={{padding:'10px'}} className="colAcc" md={12} sm={24}>
  <b style={{color:'#133165'}}>INN</b> <span style={{top:'0px'}} className="salomText">{item.inn===null?<span style={{color:'#a2a0a0'}}>{t("Ma'lumot mavjud emas")}</span>:item.inn.slice(0, 3)+" "+item.inn.slice(3, 6)+" "+item.inn.slice(6, 9)} </span>
  </Col>
 
  <Col style={{padding:'10px'}} className="colAcc" md={12} sm={24}>
   <b style={{color:'#133165'}}>MFO</b> <span style={{top:'0px'}} className="salomText">{item.bankMfo===null?<span style={{color:'#a2a0a0'}}>{t("Ma'lumot mavjud emas")}</span>:item.bankMfo} </span>
  </Col>
  <Col style={{padding:'10px'}} className="colAcc" md={12} sm={24}>
  <BsFillEnvelopeFill color="#133165" size='1.3rem'/> <span className="salomText">{item.mailIndex===null?<span style={{color:'#a2a0a0'}}>{t("Ma'lumot mavjud emas")}</span>:item.mailIndex} </span>
  </Col>
  
</Row>
                             
                            </Panel>
                    
                    ):''
                  }
                    </Collapse>
                    </div>
        </div>
        <div className="komp">
        <Row style={{width:'100%'}}>
<Col style={{padding:'5px'}} lg={4} md={24} sm={24}>
   
<h1 style={{fontSize:'25px', paddingTop:'18px'}}>{t("Viloyatlar")}</h1>
  <ul className={style.ulTab}>
  <li>
 <div className="swiper-slide_body">
                  <div  className={`slider-item_img ${index===1?`active`:null}`}  onClick={()=> setIndex(1)} >
                     <p>
                     {t("Qoraqalpog'iston")}
                    </p> 
                 </div>     
              </div>
</li>
<li>
 <div className="swiper-slide_body">
                  <div  className={`slider-item_img ${index===2?`active`:null}`}  onClick={()=>setIndex(2)}  >
                     <p>
                     {t("Andijon")}
                    </p> 
                 </div>     
              </div>
    </li>
    <li>
 <div className="swiper-slide_body">
                  <div  className={`slider-item_img ${index===3?`active`:null}`} onClick={()=>setIndex(3)} >
                     <p>
                     {t("Buxoro")}
                    </p> 
                 </div>     
              </div>
    </li>
    <li>
 <div className="swiper-slide_body">
                  <div  className={`slider-item_img ${index===12?`active`:null}`} onClick={()=>setIndex(12)} >
                     <p>
                     {t("Farg'ona")}
                    </p> 
                 </div>     
              </div>
    </li>
    <li>
 <div className="swiper-slide_body">
                  <div  className={`slider-item_img ${index===4?`active`:null}`} onClick={()=>setIndex(4)} >
                     <p>
                     {t("Jizzax")}
                    </p> 
                 </div>     
              </div>
    </li>
    <li>
 <div className="swiper-slide_body">
                  <div  className={`slider-item_img ${index===7?`active`:null}`} onClick={()=>setIndex(7)} >
                     <p>
                     {t("Namangan")}
                    </p> 
                 </div>     
              </div>
    </li>
    <li>
 <div className="swiper-slide_body">
                  <div  className={`slider-item_img ${index===6?`active`:null}`} onClick={()=>setIndex(6)} >
                     <p>
                     {t("Navoi")}
                    </p> 
                 </div>     
              </div>
    </li>
    <li>
 <div className="swiper-slide_body">
                  <div  className={`slider-item_img ${index===5?`active`:null}`} onClick={()=>setIndex(5)} >
                     <p>
                     {t("Qashqadaryo")}
                    </p> 
                 </div>     
              </div>
    </li>
    <li>
 <div className="swiper-slide_body">
                  <div  className={`slider-item_img ${index===8?`active`:null}`} onClick={()=>setIndex(8)} >
                     <p>
                     {t("Samarqand")}
                    </p> 
                 </div>     
              </div>
    </li>
    <li>
 <div className="swiper-slide_body">
                  <div  className={`slider-item_img ${index===10?`active`:null}`} onClick={()=>setIndex(10)} >
                     <p>
                     {t(" Sirdaryo")}
    </p> 
                 </div>     
              </div>
                        
    </li>
    <li>
 <div className="swiper-slide_body">
                  <div  className={`slider-item_img ${index===9?`active`:null}`} onClick={()=>setIndex(9)} >
                     <p>
                     {t("Surxondaryo")}
                     </p> 
                 </div>     
              </div>
                     
    </li>
    <li>
 <div className="swiper-slide_body">
                  <div  className={`slider-item_img ${index===11?`active`:null}`} onClick={()=>setIndex(11)} >
                     <p>
                     {t(" Toshkent vil")}
    </p> 
                 </div>     
              </div>
    </li>
    <li>
 <div className="swiper-slide_body">
                  <div  className={`slider-item_img ${index===13?`active`:null}`} onClick={()=>setIndex(13)} >
                     <p>
                     {t("Xorazm")}
                        </p> 
                 </div>     
              </div>
    </li>
    <li>
 <div className="swiper-slide_body">
                  <div  className={`slider-item_img ${index===14?`active`:null}`} onClick={()=>setIndex(14)} >
                     <p>
                     {t("Toshkent sh")}
                    </p> 
                 </div>     
              </div>
    </li>
  
  </ul>
</Col>
<Col style={{padding:'5px'}} lg={20} md={24} sm={24}>
   
<div className="content">
        <table className="content-table table1" >
            <thead style={{backgroundColor:'white', height:'65px',}}  className={style.the}>
                          <tr>
                         <th className={style.id}>
                          <Tooltip placement="top" title={t("Tartib raqam")}>
                         <MdFormatListNumbered size='2rem' color="#133165"/>
                         </Tooltip>

                           </th>    
                <th className={style.name}>
                 <Tooltip placement="top" title={t("Tashkilot nomi")}>
                <FaBuilding  size='2rem' color="#133165"/>
                </Tooltip>
                  </th>
              
                <th className={style.person} style={{paddingLeft:'40px'}}>
                 <Tooltip placement="top" title={t("Tashkilot rahbari")}>
                <ImUserTie  size='2.1rem' color="#133165" />
                </Tooltip>
                </th>
               
                <th className={style.phone}>
                 <Tooltip placement="top" title={t("Telefon raqam")}>
                <BsFillTelephoneFill size='2rem' color="#133165" />
                </Tooltip>
                  </th>
                <th className={style.email}>
                 <Tooltip placement="top" title={t("E-mail")}>
                <BsFillEnvelopeFill  size='2rem' color="#133165" />
                </Tooltip>
                  </th>
                
              </tr>   
            </thead></table>
<div className={style.op}>
                      <Collapse onChange={callback} accordion>
                        
                      <Panel className={`${0===Number(number)?"Active":''} panel`}  key={0} header={
                              <div className={style.headAcc}>
                              <div className={style.headId}>{1}</div>
                              <div className={style.headName}> {region.length!==0?t('check')?region[index - 1][0].regionName:region[index - 1][0].regionNameRu:''}</div>
                              <div className={style.headPerson}>{region.length!==0?t('check')?region[index - 1][0].regionPresident:region[index - 1][0].regionPresidentRu:''}</div>
                              <div className={style.headPhone}>{region.length!==0?region[index - 1][0].phoneNumber===null?"-":region[index - 1][0].phoneNumber:''}</div>
                              <div className={style.headEmail}>{region.length!==0?region[index - 1][0].email==="null"?"-":region[index - 1][0].email:''}</div>
                            </div>}>
                        
                            <Row>
                            <Col className="colAcc" lg={8} md={12} sm={24}>
<Tooltip placement="left" title={t("Bank nomi")}><RiBankFill  color="#133165" size='1.4rem' /> <span className="salomText">{region.length!==0?t('check')?(region[index - 1][0].bankName && region[index - 1][0].bankName!==null?region[index - 1][0].bankName:region[index - 1][0].bankNameRu && region[index - 1][0].bankNameRu!==null?region[index - 1][0].bankNameRu:<span style={{color:'#a2a0a0'}}>{t("Ma'lumot mavjud emas")}</span>):region[index - 1][0].bankNameRu && region[index - 1][0].bankNameRu!==null?region[index - 1][0].bankNameRu:region[index - 1][0].bankName && region[index - 1][0].bankName!==null?region[index - 1][0].bankName:<span style={{color:'#a2a0a0'}}>{t("Ma'lumot mavjud emas")}</span>:''}</span></Tooltip>
  </Col>
<Col className="colAcc" lg={8} md={12} sm={24}>
  <Tooltip placement="left" title={t("Tashkilot manzili")}><FaMapMarkerAlt  color="#133165" size='1.4rem' />  <span className="salomText">{region.length!==0?t('check')?(region[index - 1][0].regionAdress && region[index - 1][0].regionAdress!==null?region[index - 1][0].regionAdress:region[index - 1][0].regionAdressRu && region[index - 1][0].regionAdressRu!==null?region[index - 1][0].regionAdressRu:<span style={{color:'#a2a0a0'}}>{t("Ma'lumot mavjud emas")}</span>):region[index - 1][0].regionAdressRu && region[index - 1][0].regionAdressRu!==null?region[index - 1][0].regionAdressRu:region[index - 1][0].regionAdress && region[index - 1][0].regionAdress!==null?region[index - 1][0].regionAdress:<span style={{color:'#a2a0a0'}}>{t("Ma'lumot mavjud emas")}</span>:''}</span></Tooltip>
  </Col>
<Col className="colAcc" lg={8} md={12} sm={24}>
  <Tooltip placement="left" title={t("Tashkilot hisob raqami")}><RiBankCardFill  color="#133165" size='1.4rem' /> <span className="salomText">{region.length!==0?region[index - 1][0].bankAccount===null?<span style={{color:'#a2a0a0'}}>{t("Ma'lumot mavjud emas")}</span>:region[index - 1][0].bankAccount.slice(0, 4)+" "+region[index - 1][0].bankAccount.slice(4, 8)+" "+region[index - 1][0].bankAccount.slice(8, 12)+" "+region[index - 1][0].bankAccount.slice(12, 16)+" "+region[index - 1][0].bankAccount.slice(16, 20):''}</span></Tooltip>
  </Col>
<Col className="colAcc" lg={8} md={12} sm={24}>
  <Tooltip placement="left" title={t("INN")}><b style={{color:'#133165'}}>INN</b> <span style={{top:'0px'}} className="salomText">{region.length!==0?region[index - 1][0].inn===null?<span style={{color:'#a2a0a0'}}>{t("Ma'lumot mavjud emas")}</span>:region[index - 1][0].inn.slice(0, 3)+" "+region[index - 1][0].inn.slice(3, 6)+" "+region[index - 1][0].inn.slice(6, 9):''}</span></Tooltip>
  </Col>
<Col className="colAcc" lg={8} md={12} sm={24}>
  <Tooltip placement="left" title={t("MFO")}> <b style={{color:'#133165'}}>MFO</b> <span style={{top:'0px'}} className="salomText">{region.length!==0?region[index - 1][0].bankMfo===null?<span style={{color:'#a2a0a0'}}>{t("Ma'lumot mavjud emas")}</span>:region[index - 1][0].bankMfo:''}</span></Tooltip>
  </Col>
<Col className="colAcc" lg={8} md={12} sm={24}>
  <Tooltip placement="left" title={t("Pochta indeks")}><BsFillEnvelopeFill color="#133165" size='1.4rem'/> <span className="salomText">{region.length!==0?region[index - 1][0].mailIndex===null?<span style={{color:'#a2a0a0'}}>{t("Ma'lumot mavjud emas")}</span>:region[index - 1][0].mailIndex:''}</span></Tooltip>
  </Col>
  
</Row>
                            </Panel>
                        
                          {
                              data.length !== 0 ? data[index - 1].map((item: any, key: any) =>
                                  {
                                    
                                    return(
                              <Panel className={`${(key+1)===Number(number)?"Active":''} panel`}  key={key+1} header={
                              <div className={style.headAcc}>
                              <div className={style.headId}>{key+2}</div>
                              <div className={style.headName}> {t('check')?item.branchName:item.branchNameRu}</div>
                              <div className={style.headPerson}>{t('check')?item.branchPresident:item.branchPresidentRu}</div>
                              <div className={style.headPhone}>{item.phoneNumber===null?"-":item.phoneNumber}</div>
                              <div className={style.headEmail}>{item.email==="null"?"-":item.email}</div>
                            </div>}>
                            <Row>
<Col className="colAcc" lg={8} md={12} sm={24}>
<Tooltip placement="left" title={t("Bank nomi")}><RiBankFill  color="#133165" size='1.3rem' /> <span className="salomText">{t('check')?(item.bankName && item.bankName!==null?item.bankName:item.bankNameRu && item.bankNameRu!==null?item.bankNameRu:<span style={{color:'#a2a0a0'}}>{t("Ma'lumot mavjud emas")}</span>):item.bankNameRu && item.bankNameRu!==null?item.bankNameRu:item.bankName && item.bankName!==null?item.bankName:<span style={{color:'#a2a0a0'}}>{t("Ma'lumot mavjud emas")}</span>}</span></Tooltip>
  </Col>
 
  
  <Col className="colAcc" lg={8} md={12} sm={24}>
  <Tooltip placement="left" title={t("Tashkilot manzili")}><FaMapMarkerAlt  color="#133165" size='1.3rem' />  <span className="salomText">{t('check')?(item.branchAdress && item.branchAdress!==null?item.branchAdress:item.branchAdressRu && item.branchAdressRu!==null?item.branchAdressRu:<span style={{color:'#a2a0a0'}}>{t("Ma'lumot mavjud emas")}</span>):item.branchAdressRu && item.branchAdressRu!==null?item.branchAdressRu:item.branchAdress && item.branchAdress!==null?item.branchAdress:<span style={{color:'#a2a0a0'}}>{t("Ma'lumot mavjud emas")}</span>}</span></Tooltip>
  </Col>
  <Col className="colAcc" lg={8} md={12} sm={24}>
  <Tooltip placement="left" title={t("Tashkilot hisob raqami")}><RiBankCardFill  color="#133165" size='1.3rem' /> <span className="salomText">{item.bankAccount===null?<span style={{color:'#a2a0a0'}}>{t("Ma'lumot mavjud emas")}</span>:item.bankAccount.slice(0, 4)+" "+item.bankAccount.slice(4, 8)+" "+item.bankAccount.slice(8, 12)+" "+item.bankAccount.slice(12, 16)+" "+item.bankAccount.slice(16, 20)} </span></Tooltip>
  </Col>
  <Col className="colAcc" lg={8} md={12} sm={24}>
  <Tooltip placement="left" title={t("INN")}><b style={{color:'#133165'}}>INN</b> <span style={{top:'0px'}} className="salomText">{item.inn===null?<span style={{color:'#a2a0a0'}}>{t("Ma'lumot mavjud emas")}</span>:item.inn.slice(0, 3)+" "+item.inn.slice(3, 6)+" "+item.inn.slice(6, 9)} </span></Tooltip>
  </Col>
 
  <Col className="colAcc" lg={8} md={12} sm={24}>
  <Tooltip placement="left" title={t("MFO")}><b style={{color:'#133165'}}>MFO</b> <span style={{top:'0px'}} className="salomText">{item.bankMfo===null?<span style={{color:'#a2a0a0'}}>{t("Ma'lumot mavjud emas")}</span>:item.bankMfo} </span></Tooltip>
  </Col>
  <Col className="colAcc" lg={8} md={12} sm={24}>
  <Tooltip placement="left" title={t("Pochta indeks")}><BsFillEnvelopeFill color="#133165" size='1.3rem'/> <span className="salomText">{item.mailIndex===null?<span style={{color:'#a2a0a0'}}>{t("Ma'lumot mavjud emas")}</span>:item.mailIndex} </span></Tooltip>
  </Col>
  
</Row>
                            </Panel>
                    )}
                    ):''
                  }
                    </Collapse>
                    </div>
        </div>

        
</Col>
        </Row>
        </div>
      
                    
                   

        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      </div>
    </div>
  );
}
export default Contacts;
