import { create } from "zustand";


export const useStore=create((set)=>({
    sports:null,
    setsports:(value)=>set((state)=>({sports:value})),
    jobs:null,
    setjobs:(value)=>set((state)=>({jobs:value})),
    Categories:null,
    setCategories:(value)=>set((state)=>({Categories:value})),
   
}))