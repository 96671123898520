import http from '../components/ui/Services';
import {useEffect} from 'react'

import './Sports.css'
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { api } from '../host';
import { PageTitle } from '../components/ui/PageTitle';
import { Link } from 'react-router-dom';
import { useStore } from '../store/Store';
export default function Sports() {
    const sports=useStore(state=>state.sports)
    const setsports=useStore(state=>state.setsports)
    const {t}=useTranslation()
    useEffect(()=>{
        if(sports===null){
            http.get<any>(`/GetApi/GetSportsApi/`)
          
            .then((res) => {console.log(res.data); setsports(res.data); })
            .catch(e => console.log(e))
        }
    
    },[])
  return (
    <div className='hujjatlar sports'>
        <div className="container">
         <PageTitle title={t('sports')} />
        <Row style={{justifyContent:'center'}}>
            {sports!==null?sports.map((item:any, key:any)=>{
                return( <Col style={{padding:'10px'}} key={key} lg={8} md={12} sm={24}>
                    <Link to={`/sportlar/${key}`} className='sports_card'>
                        <div className='sports_text'>
                            <p>
                            {t('check')?item.titleUz:item.titleRu}
                            </p>
                        </div>
                        <img src={`${api}/Files/Sports/${item.titleImage}`}/>
                    </Link>
                  </Col>)
            }):<></>}
         
        </Row>
    </div>
    </div>
  )
}
