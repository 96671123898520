import "./assets/anticor.css";
import { useTranslation } from 'react-i18next';

import { PageTitle } from "../ui/PageTitle";
import { Container, Row } from "react-bootstrap";
import { FaArrowCircleRight } from "react-icons/fa";
export default function Anticor() {
    const {t} = useTranslation();
  
 
  return (
    <div className="anti">
      <br/>
        <PageTitle title={t('korrupsiya')} />
        <Container>
        
        <div className="anticor">
       
        <a href={t("check")?"https://lex.uz/docs/-3088008":"https://lex.uz/docs/3088013"} target="_blank" className="anticor_item">
                    <h1>{t('anticor_1')}</h1>
                    <p><FaArrowCircleRight/></p>
                   </a>
                   
                   <a href={t("check")?"https://lex.uz/docs/-5495533":"https://lex.uz/docs/5495538"} target="_blank" className="anticor_item">
                    <h1>{t('anticor_2')}</h1>
                    <p><FaArrowCircleRight/></p>
                   </a>
                   
                   <a href={t("check")?"https://lex.uz/docs/-6066512":"https://lex.uz/docs/6066514"} target="_blank" className="anticor_item">
                    <h1>{t('anticor_3')}</h1>
                    <p><FaArrowCircleRight/></p>
                   </a>
                 
                 <div className="elon_kor">{t("anticor_4")}</div>
        </div>
        </Container>
    </div>
  )
}
