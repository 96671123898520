import {
  Form,

  Select,

} from 'antd';

import logo from './assets/logo.gif'
import anonim from './assets/anonim.png'

import style from './Taklif.module.css'
import "./assets/murojat.scss";
import 'antd/dist/antd.css';

import { useEffect, useState } from "react";
import { PageTitle } from "../ui/PageTitle";
import { useTranslation } from "react-i18next";
import { Col, Row ,  Tooltip } from 'antd';
import { BsCommand, BsFlagFill } from 'react-icons/bs';
import { AiFillDislike, AiFillLike, AiOutlineClockCircle, AiOutlineDislike, AiOutlineEye, AiOutlineLike } from 'react-icons/ai';
import { MdAllInclusive, MdDateRange, MdSportsBaseball, MdWork } from 'react-icons/md';
import { FaHandsHelping, FaTelegramPlane } from 'react-icons/fa';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { Link } from 'react-router-dom';
import http from '../ui/Services';
import { useCookies } from 'react-cookie';
import Cookies from 'js-cookie';
import { GiMechanicGarage } from 'react-icons/gi';
import type { RadioChangeEvent } from 'antd';
import { Radio } from 'antd';
import { setConstantValue } from 'typescript';
const layout = {
  labelCol: { span: 2 },
  wrapperCol: { span: 22 },
};
const { Option } = Select;

/* eslint-disable no-template-curly-in-string */

/* eslint-enable no-template-curly-in-string */

function Taklif() {
  const [loader, setLoader] = useState(true)
  const [soha, setSoha] = useState(0)
  const [page, setpage] = useState(1)
  const [overflow, setoverflow] = useState(-1)
  const [data, setdata] = useState<any>([])
  const [stat, setStat] = useState<any>(null)
  const [data1, setdata1] = useState<any>([])
  const [form] = Form.useForm();
  const [cookies, setCookie] = useCookies<any>([]);
  const [value, setValue] = useState(1);
  const [value1, setValue1] = useState(0);

  const onChange = (e: RadioChangeEvent) => {
    setpage(1)
    setoverflow(-1)
      setValue(e.target.value);
      getTaklif(1, soha, e.target.value, value1)
    };
    const onChange1 = (e: RadioChangeEvent) => {
      setpage(1)
      setoverflow(-1)
        setValue1(e.target.value);
        getTaklif(1, soha, value, e.target.value)
      };
    const { t } = useTranslation();
 
  var toonavatar = require('cartoon-avatar');
  
  
  const setLike=(id:any)=>{
   var tLike=1
    var b=""
    if(!!Cookies.get('like')){
    b=String(Cookies.get('like'))
      b.replaceAll("%2C", ',')
      }
      if((b.indexOf(id+','))===-1){
        b=b+`${id},`
        }else{
          if((b.indexOf("-"+id+','))!==-1){
            var c=b.slice(0,b.indexOf("-"+id+','))+b.slice(b.indexOf("-"+id+',')+String(id).length+2, b.length)+`${id},`
b=c
tLike=111
          }
          else{
            var c=b.slice(0,b.indexOf(id+','))+b.slice(b.indexOf(id+',')+String(id).length+1, b.length)
b=c
tLike=11
          }            
        }
         setCookie("like", b, {
              maxAge: 7*24*60*60 
           });
    http.post<any>(`/PostApi/TaklifReaction/?id=${id}&reaction=${tLike}`, {}, {
   }).then(res=>{
   
      getTaklifRet(page)
    })
  }
  
  
  const setDisLike=(id:any)=>{
    var tLike=-1
    var b=""
    if(!!Cookies.get('like')){
    b=String(Cookies.get('like'))
      b.replaceAll("%2C", ',')
      }
      if((b.indexOf(id+','))===-1){
        b=b+`-${id},`
        }else{
          if((b.indexOf("-"+ id+','))!==-1){
            var c=b.slice(0,b.indexOf("-"+id+','))+b.slice(b.indexOf("-"+id+',')+String(id).length+2, b.length)
           
b=c
tLike=-11
          }
          else{
            var c=b.slice(0,b.indexOf(id+','))+b.slice(b.indexOf(id+',')+String(id).length+1, b.length)+`-${id},`
b=c
tLike=-111
          }            
        }
         setCookie("like", b, {
              maxAge: 7*24*60*60 
           });
    http.post<any>(`/PostApi/TaklifReaction/?id=${id}&reaction=${tLike}`, {}, {
   }).then(res=>{
   
      getTaklifRet(page)
    })
  }
  const getTaklifRet=(p:any)=>{
    setLoader(true)
    var f=String(Cookies.get('seen'))
    if(!!Cookies.get('seen')){
      f.replaceAll("%2C", ',')
    }
var h=data
h=[]
    for(let i=1; i<=p;i++){
      http.get<any>(`/GetApi/GetTakliflar/?Page=${i}&SohaId=${soha}&Korilganlar=${f.indexOf(',')===-1?"0,0":f.slice(0, f.lastIndexOf(','))}&Sort=${value}&Type=${value1}`)
      .then((res) => {
h=h.concat(res.data)
setdata(h)

      })
  
    }
  
  
 
  setLoader(false)
    
   }  
  
const getTaklif=(p:any, s:any, t:any, v:any)=>{
  
  var f=String(Cookies.get('seen'))
  if(!!Cookies.get('seen')){
    f.replaceAll("%2C", ',')
  }
  http.get<any>(`/GetApi/GetTakliflar/?Page=${p}&SohaId=${s}&Korilganlar=${f.indexOf(',')===-1?"0,0":f.slice(0, f.lastIndexOf(','))}&Sort=${t}&Type=${v}`)
  .then((res) => {
   if(res.data.length!==0){
 var a=[]
 if(s===soha){
  a=data
   
  }
  if(t!==value){
    a=[]
     
    }
    if(v!==value1){
      a=[]
       
      }
    var b=""
    if(!!Cookies.get('seen')){
    b=String(Cookies.get('seen'))
      b.replaceAll("%2C", ',')
      }
 
 
      for(let i=0; i<res.data.length; i++){
  if((b.indexOf(res.data[i].id+','))===-1){
  b=b+`${res.data[i].id},`
  }
  
      }
      setCookie("seen", b, {
        maxAge: 24*60*60 
     });
      setdata(a.concat(res.data));
      
      
      http.get<any>(`/GetApi/GetTakliflar/?Page=${p+1}&SohaId=${s}&Korilganlar=${f.indexOf(',')===-1?"0,0":f.slice(0, f.lastIndexOf(','))}&Sort=${t}&Type=${v}`)
      .then((resq) => {
        setdata1(resq.data);
      
      setLoader(false)
      })
      .catch(e => console.log(e))
   }else{
    setLoader(false)
   }
    
  })
  .catch(e => console.log(e))
}
useEffect(()=>{
  http.get<any>(`/GetApi/GetTakliflarStat`).then(res=>{
    setStat(res.data)
  })
  if(!!Cookies.get('like')){
   console.log('salom')
  }else{
    setCookie("like", "g,", {
      maxAge: 7*24*60*60 
   });
  }
getTaklif(page, soha, value, value1)
},[])
  return ( <div className="murojat taklif" >
  {loader?<div className="loaderG">
  <div className="befG">
<img src={logo} alt="..."/>
</div>

</div>
:''}
  <div className="container">
      <PageTitle title={t('Takliflar')} />
      <br/>
      <br/>
      <br/>
      <br/>
      <div className="form">
 <Row>
 <Col className={style.cl} style={{padding:'5px'}} lg={6} md={12} sm={24}>
    <div onClick={()=>{if(soha!==0){getTaklif(1, 0, value, value1); setdata([]);  setSoha(0); setpage(1); }; }} className={soha===0?style.active:style.card}>
<div className={style.icon}><MdAllInclusive/></div>
<h4 style={{fontSize:t("check")?'22px':'20px'}}>{t("Barchasi")}</h4>
<div className={style.number}>{stat!==null?stat[0].total:''}</div>
    </div>
  </Col>
  <Col className={style.cl} style={{padding:'5px'}} lg={6} md={12} sm={24}>
    <div onClick={()=>{if(soha!==1){getTaklif(1, 1, value, value1); setdata([]);  setSoha(1); setpage(1); }; }} className={soha===1?style.active:style.card}>
<div className={style.icon}><BsFlagFill/></div>
<h4 style={{fontSize:t("check")?'22px':'20px'}}>{t("Vatanparvarlik harakati yo'nalishi")}</h4>
<div className={style.number}>{stat!==null?stat[0].soha1:''}</div>
    </div>
  </Col>
  <Col className={style.cl} style={{padding:'5px'}} lg={6} md={12} sm={24}>
    <div onClick={()=>{if(soha!==3){getTaklif(1, 3, value, value1); setdata([]);  setSoha(3); setpage(1); }; }} className={soha===3?style.active:style.card}>
<div className={style.icon}><MdSportsBaseball/></div>
<h4 style={{fontSize:t("check")?'22px':'20px'}}>{t("Sport yo'nalishi")}</h4>
<div className={style.number}>{stat!==null?stat[0].soha3:''}</div>
    </div>
  </Col>
  <Col className={style.cl} style={{padding:'5px'}} lg={6} md={12} sm={24}>
    <div onClick={()=>{if(soha!==2){getTaklif(1, 2, value, value1); setdata([]);  setSoha(2); setpage(1); }; }} className={soha===2?style.active:style.card}>
<div className={style.icon}><GiMechanicGarage/></div>
<h4 style={{fontSize:t("check")?'22px':'20px'}}>{t("Kasbga tayyorlash yo'nalishi")}</h4>
<div className={style.number}>{stat!==null?stat[0].soha2:''}</div>
    </div>
  </Col>
  
 </Row>
<br/>
<br/>
<Link to="/taklif_yuborish" className={style.btn}>{t("Taklif yuborish")} <FaTelegramPlane style={{ fontSize:'23px', position:'relative', top:'4px', left:'5px'}}/></Link>
<br/>
<div className={style.radiolar}>
<Radio.Group className={style.radios} onChange={onChange} value={value}>
      <Radio className={style.radio} value={1}>{t("yangi")}</Radio>
      <Radio className={style.radio} value={2}>{t("eski")}</Radio>
      <Radio className={style.radio} value={3}>{t("like")}</Radio>
      <Radio className={style.radio} value={5}>{t("dislike")}</Radio>
    </Radio.Group>
    <Radio.Group className={style.radios} onChange={onChange1} value={value1}>
    <Radio className={style.radio} value={0}>{t("barchasi")}</Radio>
      <Radio className={style.radio} value={1}>{t("anonimlar")}</Radio>
      <Radio className={style.radio} value={2}>{t("ochiq takliflar")}</Radio>
   
    </Radio.Group>

    </div>
<br/>
<Row>
  {data.map((item:any, key:any)=>{
    return(<Col lg={24}className={style.colTak}>
    <div className={style.comment}>
      <Row>
      <Col  style={{position:'relative'}} lg={6} sm={24}>
      <Row>
      <Col style={{display:'flex', height:'100px', justifyContent:'flex-end', alignItems:'center', marginTop:'-25px', paddingRight:'10px'}} lg={5}>
          <img src={toonavatar.generate_avatar({"gender":"male","id":key})} />
        </Col>
        <Col lg={19}>
          <h3 className={style.fio}>{item.fullName}</h3>
        </Col>
      
        <Col className={style.ic} lg={24}>
          <ul className={style.icons}>
          <li><p style={{color:'green', fontWeight:'700'}}>{String(Cookies.get('like')).indexOf(`${item.id},`)===-1 || String(Cookies.get('like')).indexOf(`-${item.id},`)!==-1?<AiOutlineLike   onClick={()=>{setLike(item.id)}} className={style.icT} style={{ position:'relative', top:'4px'}}/>:<AiFillLike   onClick={()=>{setLike(item.id)}} className={style.icT} style={{ position:'relative', top:'4px'}}/>} <span>{item.like}</span></p></li> 
          <li><p  style={{color:'red', fontWeight:'700'}}>{String(Cookies.get('like')).indexOf(`-${item.id},`)===-1?<AiOutlineDislike   onClick={()=>{setDisLike(item.id)}} className={style.icT} style={{ position:'relative', top:'7px'}}/>:<AiFillDislike   onClick={()=>{setDisLike(item.id)}} className={style.icT} style={{ position:'relative', top:'7px'}}/>} <span>{item.dislike}</span></p></li> 
         
          </ul>
        </Col>
      </Row>
     </Col>
     <Col lg={18} sm={24}>
      <Row>
      <Col lg={24} sm={24}>
      <h3 className={style.fio1}>{item.murojaatMazmuni}</h3>
   
      <p id={item.murojaatMatni.length>350 && overflow===key?"dec":"decA"} className={style.text}>{item.murojaatMatni}</p>
      
        </Col>
        <Col  style={{display:'flex', justifyContent:'flex-end', paddingRight:'10px'}} lg={24}>
<p className={style.strel} onClick={()=>{overflow===key?setoverflow(-1):setoverflow(key);}} >{item.murojaatMatni.length>350 && overflow===key?<IoIosArrowUp style={{fontSize:'24px', position:'relative', top:'6px'}}/>:<IoIosArrowDown style={{fontSize:'24px', position:'relative', top:'6px'}}/>}</p>
       
<p><MdDateRange style={{fontSize:'24px', position:'relative', top:'6px'}}/> {item.createDate.substring(8, 10)+'.'+item.createDate.substring(5, 7)+'.'+item.createDate.substring(0, 4)}</p>
<p><AiOutlineClockCircle style={{fontSize:'24px', position:'relative', top:'6px'}}/> {item.createDate.substring(11, 16)}</p>
      
<p><AiOutlineEye  style={{fontSize:'24px', position:'relative', top:'6px'}}/> {item.korilganSon}</p>
        </Col>
      </Row>
     </Col>
      
        
      </Row>
    </div>
  </Col>)
  })}

 
</Row>
<br/>
<br/>
{data1.length!==0?<button onClick={()=>{setpage(page+1); getTaklif(page+1, soha, value, value1)}} className={style.btn}>{t("Davomini ko'rish")} <FaTelegramPlane style={{ fontSize:'23px', position:'relative', top:'4px', left:'5px'}}/></button>:""}

    </div>
            </div>
        </div>
  );
};

export default Taklif;