import React from 'react';
import { AppNavbar } from "./AppNavbar";
import { AppFooter } from "./AppFooter";
import { Home } from "../home/Home";
import {
    Routes,
    Route,
    BrowserRouter
} from "react-router-dom";
import Aparat from '../aboutus/Aparat/Aparat';
import History from '../aboutus/history/History';
import AllNews from '../information/allnews/AllNews';
import Contacts from '../contacts/Contacts';
import Kasbxunar from '../enquiries/VocationalEducation';
import Taklif from '../enquiries/Taklif';
import FirstPage from '../home/activity/FirstPage';
import SixPage from '../home/activity/SixPage';
import SecondPage from '../home/activity/SecondPage';
import ThirdPage from '../home/activity/ThirdPage';
import FourthPage from '../home/activity/FourthPage';
import Fifthpage from '../home/activity/Fifthpage';
import OpenNews from '../information/allnews/OpenNews';
import Regions from '../aboutus/regions/Regions';
import Cooperation from '../aboutus/cooperation/Cooperation';
import Central from '../aboutus/central/Central';
import Leaders from '../aboutus/leaders/Leaders';
import PhotoGallery from '../information/photoGallery/PhotoGallery';
import Subsidiaries from '../aboutus/subsidiaries/Subsidiaries';
import Documents from '../d-ocuments/Documents';
import ToDrivingSchool from '../enquiries/ToDrivingSchool';
import OnlineEnquiries from '../enquiries/OnlineEnquiries';
import Error from './Error';
import { Xarita } from '../../Xarita';
import TaklifSend from '../enquiries/TaklifSend';
import { Helmet } from 'react-helmet-async';
import CallCenter from '../younges/call-center/CallCenter';
import AllNewsYoung from '../younges/youngnews/AllNewsYoung';
import OpenNewsYoung from '../younges/youngnews/OpenNewsYoung';
import Document from '../younges/documents/Document';
import Sportsmens from '../younges/sportsmens/Sportsmens';
import Vacancy from '../vacancy/Vacancy';
import Anticor from '../anticor/Anticor';
import Sports from '../../sports/Sports';
import OpenSport from '../../sports/OpenSport';
import OpenJob from '../../jobs/OpenJob';
import Jobs from '../../jobs/Jobs';
import Categories from '../../categories/Categories';
import OpenCategories from '../../categories/OpenCategories';


export function AppContainer() {
    return (
        <BrowserRouter>
        <div className="wrapper app">
            <AppNavbar />
            <main className="main"> <Routes>
            <Route path='*' element={<Error />} />
            <Route path="/allnews/ru/:id" element={<OpenNews />} />
            <Route path="/allnews/:id" element={<OpenNews />} />
            <Route path="/" element={<>  <Helmet>
<title>O’ZBEKISTON RESPUBLIKASI MUDOFAASIGA KO’MAKLASHUVCHI “VATANPARVAR” TASHKILOTI RASMIY VEB SAYTI</title>
<meta name="description" content="O'zbekiston Respublikasida yashovchi 18 yoshdan katta fuqarolar haydovchilik guvohnomasi olish uchun “Vatanparvar” tashkiloti avtomaktablariga murojaat qilishlari mumkin."></meta>
<link rel="canonical" href="https://vatanparvar.uz/" />    
          </Helmet><Home /></>}></Route>
                <Route path="/markaziy-kengash" element={<><Helmet>
<title>O’ZBEKISTON RESPUBLIKASI MUDOFAASIGA KO’MAKLASHUVCHI “VATANPARVAR” TASHKILOTI RASMIY VEB SAYTI</title>
<meta name="description" content="O'zbekiston Respublikasida yashovchi 18 yoshdan katta fuqarolar haydovchilik guvohnomasi olish uchun “Vatanparvar” tashkiloti avtomaktablariga murojaat qilishlari mumkin."></meta>
<link rel="canonical" href="https://vatanparvar.uz/markaziy-kengash" />    
          </Helmet><Central /></>} />
                <Route path="/rahbariyat" element={<Leaders />} />
                <Route path="/markaziy-aparat" element={<Aparat />} />
                <Route path="/tassarufidagi-tashkilotlar" element={<Subsidiaries />} />
                <Route path="/hududiy-kengashlar" element={<Regions />} />
                <Route path="/tashkilot-tarixi" element={<History />} />
                <Route path="/xalqaro-hamkorlik" element={<Cooperation />} />
                <Route path="/allnews" element={<AllNews />} />
                <Route path="/fotogalareya" element={<PhotoGallery />} />
                <Route path="/hujjatlar" element={<Documents />} />
                <Route path="/yoshlar-hujjatlar" element={<Document />} />
                <Route path="/kontaktlar" element={<Contacts />} />
                <Route path="/onlayn-murojat" element={<OnlineEnquiries />} />
                <Route path="/haydovchilik-maktabiga-yozilish" element={<ToDrivingSchool />} />
                <Route path="/kasb-hunar-maktabiga-yozilish" element={<Kasbxunar />} />
                <Route path="/yoshlar-va-fuqarolarni-manaviy-axloqiy" element={<FirstPage />} />
                <Route path="/sportning-texnik-va-amaliy-turlarini" element={<SecondPage />} />
                <Route path="/bolalar-osmirlar-va-chaqiruvgacha" element={<ThirdPage />} />
                <Route path="/yoshlar-va-fuqarolarni-mudofaa" element={<FourthPage />} />
                <Route path="/avtomototransport-vositalari-haydovchilarini" element={<Fifthpage />} />
                <Route path="/xalqaro-tashkilotlar-bilan-faol-hamkorlik-qilish" element={<SixPage />} />
                <Route path="/korrupsiya" element={<Anticor />} />
                <Route path="/yosh-qahramonlar" element={<Sportsmens />} />
                <Route path="/xarita/:id" element={<Xarita />} />
                <Route path="/takliflar" element={<Taklif />}></Route>
                <Route path="/vacancy" element={<Vacancy />}></Route>
                <Route path="/sportlar" element={<Sports />}></Route>
                <Route path="/sportlar/:id" element={<OpenSport />}></Route>
                <Route path="/kasblar" element={<Jobs />}></Route>
                <Route path="/kasblar/:id" element={<OpenJob />}></Route>
                <Route path="/haydovchilik" element={<Categories />}></Route>
                <Route path="/haydovchilik/:id" element={<OpenCategories />}></Route>
                <Route path="/takliflar/ru" element={<Taklif />}></Route>
                <Route path="/taklif_yuborish" element={<TaklifSend />}></Route>
                <Route path="/taklif_yuborish/ru" element={<TaklifSend />}></Route>
               

                
                <Route path="/ru" element={<Home />}></Route>
                <Route path="/sportlar/ru" element={<Sports />}></Route>
                <Route path="/sportlar/ru/:id" element={<OpenSport />}></Route>
                <Route path="/kasblar/ru" element={<Jobs />}></Route>
                <Route path="/kasblar/ru/:id" element={<OpenJob />}></Route>

                <Route path="/haydovchilik/ru" element={<Categories />}></Route>
                <Route path="/haydovchilik/ru/:id" element={<OpenCategories />}></Route>

                <Route path="/markaziy-kengash/ru" element={<Central />} />
                <Route path="/call-center/ru" element={<CallCenter />} />
                <Route path="/call-center" element={<CallCenter />} />
                <Route path="/rahbariyat/ru" element={<Leaders />} />
                <Route path="/markaziy-aparat/ru" element={<Aparat />} />
                <Route path="/tassarufidagi-tashkilotlar/ru" element={<Subsidiaries />} />
                <Route path="/hududiy-kengashlar/ru" element={<Regions />} />
                <Route path="/tashkilot-tarixi/ru" element={<History />} />
                <Route path="/xalqaro-hamkorlik/ru" element={<Cooperation />} />
                <Route path="/allnews/ru" element={<AllNews />} />
                <Route path="/fotogalareya/ru" element={<PhotoGallery />} />
                <Route path="/hujjatlar/ru" element={<Documents />} />
                <Route path="/yoshlar-hujjatlar/ru" element={<Document />} />
                <Route path="/yosh-qahramonlar/ru" element={<Sportsmens />} />
                <Route path="/vacancy/ru" element={<Vacancy />}></Route>
                <Route path="/kontaktlar/ru" element={<Contacts />} />
                <Route path="/onlayn-murojat/ru" element={<OnlineEnquiries />} />
                <Route path="/haydovchilik-maktabiga-yozilish/ru" element={<ToDrivingSchool />} />
                <Route path="/kasb-hunar-maktabiga-yozilish/ru" element={<Kasbxunar />} />
                <Route path="/yoshlar-va-fuqarolarni-manaviy-axloqiy/ru" element={<FirstPage />} />
                <Route path="/sportning-texnik-va-amaliy-turlarini/ru" element={<SecondPage />} />
                <Route path="/bolalar-osmirlar-va-chaqiruvgacha/ru" element={<ThirdPage />} />
                <Route path="/yoshlar-va-fuqarolarni-mudofaa/ru" element={<FourthPage />} />
                <Route path="/avtomototransport-vositalari-haydovchilarini/ru" element={<Fifthpage />} />
                <Route path="/xalqaro-tashkilotlar-bilan-faol-hamkorlik-qilish/ru" element={<SixPage />} />
                <Route path="/xarita/ru/:id" element={<Xarita />} />
                <Route path="/yoshlar-uchun-yangiliklar" element={<AllNewsYoung />} />
                <Route path="/yoshlar-uchun-yangiliklar/ru" element={<AllNewsYoung />} />
                <Route path="/korrupsiya/ru" element={<Anticor />} />
                <Route path="/yoshlar-uchun-yangiliklar/ru/:id" element={<OpenNewsYoung />} />
            <Route path="/yoshlar-uchun-yangiliklar/:id" element={<OpenNewsYoung />} />

            </Routes></main>
            <AppFooter />
        </div></BrowserRouter>
    );
}

